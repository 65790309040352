

export const compareObjectStructure:boolean = (obj1:object, obj2:object):boolean => {
  for (const key in obj1) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj1[key] !== typeof obj2[key]) {
        return false;
      }

      if (typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
        if (!compareObjectStructure(obj1[key], obj2[key])) {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  for (const key in obj2) {
    if (obj1.hasOwnProperty(key)) {
      if (typeof obj2[key] !== typeof obj1[key]) {
        return false;
      }

      if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
        if (!compareObjectStructure(obj2[key], obj1[key])) {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  return true;
};

export const formatMessage = (message:string, ...args:any[]):string => {
  // Replace backticks with <code> tags
  const formattedMessage = message.replace(/`([^`]+)`/g, '<code>$1</code>');
  return formattedMessage;
};
