import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { compareObjectStructure, formatMessage } from '../../app/utils/utils';

export interface RawMessage {
  role: 'assistant'|'user'|'system',
  content: string,
  code?: string,
  version?: string
};

export interface Message {
  position: "left" | "right",
  type: string,
  title: "UI Pilot" | "You" | "System",
  text: string,
  rawMessageIndex?: Number,
  pendingDelete?: boolean,
  resetInProgress?: boolean
}

export interface ChatState {
  rawMessages: RawMessage[],
  messages: Message[],
  status: "idle" | "loading" | "failed",
  code: string,
  codeBeforeReset: string,
  userMessage: string,
  oldUserMessage: string,
  resetInProgress: boolean
};

const initialCode:String = `
import React from 'react';

function App() {
  return (
    <>
      <h2>Welcome!</h2>
      
      <p>
        UI Pilot is an AI-powered code generator. You can ask it to create a form-based UI for you and it'll provide the code and a running example here.
      </p>
      
      <p>
        Try typing something like:
      </p>
      
      <blockquote>
        create a form with fields for first and last name
      </blockquote>

      <p>
        Give it some time to generate the code. Afterwards, you can ask it to make further changes, like "add a submit button".
      </p>
    </>
  );
}

export default App;
`;

const initialState:ChatState = {
  rawMessages: [],
  messages: [],
  status: 'idle',
  code: initialCode,
  codeBeforeReset: '',
  userMessage: '',
  oldUserMessage: '',
  resetInProgress: false
};

const systemMessages = [
  {
    position: 0,
    append: true,
    message: {
      role: 'system',
      content: 'Hello, I\'m UI Pilot! Describe the user interface you want to build. For example, you can say "create a form with fields for first and last name."'
    }
  },
  {
    position: 2,
    message: {
      role: 'system',
      content: 'Hang tight while we generate your code and UI - it can take a little while. Afterwards, you can ask for changes like, "add a submit button".'
    }
  }
];

const convertRawMessage = (rawMessage:RawMessage, rawMessageIndex:Number):Message => {
  // console.log('@ convertRawMessage', rawMessageIndex, rawMessage);
  const position = rawMessage.role === 'assistant' || rawMessage.role === 'system' ? 'left' : 'right';
  const title = rawMessage.role === 'assistant' ? 'UI Pilot' : rawMessage.role === 'system' ? 'System' : 'You';
  const type = rawMessage.role === 'system' ? 'system' : 'text';
  return {
    position,
    type,
    title,
    rawMessageIndex,
    text: formatMessage(rawMessage.content),
  }
};

const convertRawMessages = (rawMessages:RawMessage[]):Message[] => {
  // console.log('@ convertRawMessages', rawMessages);
  const messages = rawMessages.map(convertRawMessage);
  for (let i = 0; i < systemMessages.length; i++) {
    const systemMessage = systemMessages[i];
    if (systemMessage.position <= messages.length) {
      messages.splice(systemMessage.position, 0, convertRawMessage(systemMessage.message));
    }
  }
  return messages;
};
initialState.messages = convertRawMessages(initialState.rawMessages);

const markResetInProgress = (messages:Message[], rawMessageIndex:number):Message[] => {
  const newMessages = [...messages];
  let hasPassed = false;
  for (let i = 0; i < newMessages.length; i++) {
    const newMessage = newMessages[i];
    if (newMessage.rawMessageIndex && newMessage.rawMessageIndex === rawMessageIndex) {
      hasPassed = true;
      newMessage.resetInProgress = true;
      newMessage.pendingDelete = false;
    } else if (hasPassed) {
      newMessage.pendingDelete = true;
      newMessage.resetInProgress = false;
    } else {
      newMessage.pendingDelete = false;
      newMessage.resetInProgress = false;
    }
  }
  return newMessages;
};

const markResetNotInProgress = (messages:Message[]):Message[] => {
  const newMessages = [...messages];
  for (let i = 0; i < newMessages.length; i++) {
    const newMessage = newMessages[i];
    newMessage.resetInProgress = false;
    newMessage.pendingDelete = false;
  }
  return newMessages;
};

const messageListForApi = (rawMessages:RawMessage[]) => {
  // Remove the version
  // console.log('rawMessages', rawMessages);
  const messages = rawMessages.map((rawMessage) => {
    return {
      role: rawMessage.role,
      content: rawMessage.content
    }
  });
  return messages;
};

export const sendPrompt = createAsyncThunk(
  "chat/sendPrompt",
  async (message: string, { getState, rejectWithValue }) => {
    const newRawMessage = {role: 'user', content: message} as RawMessage;
    const state = getState() as RootState;
    const messages = messageListForApi(state.chat.rawMessages);
    const code = state.chat.rawMessages.length === 1 ? '' : state.chat.code;
    
    const url = __PROMPT_SERVICE_URL__;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        messages,
        code
      })
    };

    try {
      const response = await fetch(url, options);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return rejectWithValue('Error: Failed to load prompt');
    }
  },
)

const doesStateMatchFormat = (state) => {
  return compareObjectStructure(initialState, state.chat);
};

const invalidStateMessage = {role: 'system', content: 'Welcome back! There was a problem loading your previously-saved session, so we have reset everything for you. Go ahead and write a prompt to get started.'} as RawMessage;

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateUserMessage: (state, action: PayloadAction<string>) => {
      state.userMessage = action.payload;
    },
    reloadSavedState: (state, action: PayloadAction<ChatState>) => {
      console.log('@ reloadSavedState', action.payload);

      try {
        const oldStateVersion = action.payload.version;
        const versionParts = oldStateVersion.split('.');
        const currentVersion = APP_VERSION;
        const currentVersionParts = currentVersion.split('.');

        if (currentVersionParts[0] !== versionParts[0]) {
          state.messages[0] = convertRawMessage(invalidStateMessage);
          return;
        }
        const oldState = action.payload.state;
        if (doesStateMatchFormat(oldState)) {
          state.rawMessages = oldState.chat.rawMessages;
          state.messages = convertRawMessages(oldState.chat.rawMessages);
          state.userMessage = oldState.chat.userMessage;
          state.code = oldState.chat.code;
        }
      } catch (error) {
        state.messages[0] = convertRawMessage(invalidStateMessage);
        console.warn('Unable to load saved state: ', action.payload, error);
      }
    },
    clearChat: (state) => {
      state.messages = convertRawMessages(initialState.rawMessages);
      state.rawMessages = initialState.rawMessages;
      state.code = initialState.code;
    },
    beginReset: (state, action) => {
      const rawMessage = state.rawMessages[action.payload];
      const newMessages = markResetInProgress(state.messages, action.payload);
      state.messages = newMessages;
      state.codeBeforeReset = state.code;
      state.code = state.rawMessages[action.payload].code;
      state.resetInProgress = true;
    },
    confirmReset: (state, action) => {
      let newRawMessages = [...state.rawMessages];
      newRawMessages = newRawMessages.splice(0, action.payload+1);
      state.rawMessages = newRawMessages;
      let newMessages =  state.messages.filter(message => !message.pendingDelete);
      newMessages = newMessages.map((message) => {
        if (message.resetInProgress) {
          message.resetInProgress = false;
        }
        return message;
      });
      state.messages = newMessages;
      state.codeBeforeReset = '';
      state.resetInProgress = false;
    },
    cancelReset: (state, action) => {
      state.code = state.codeBeforeReset;
      state.resetInProgress = false;
      const newMessages = markResetNotInProgress(state.messages);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPrompt.pending, (state, action) => {
        const userRawMessage = {role: 'user', content: action.meta.arg} as RawMessage;
        state.rawMessages.push(userRawMessage);
        state.messages = convertRawMessages(state.rawMessages);
        state.oldUserMessage = state.userMessage;
        state.userMessage = '';
        state.status = 'loading';
      })
      .addCase(sendPrompt.fulfilled, (state, action) => {
        console.log('@ sendPrompt.fulfilled', action.payload);
        state.status = 'idle';
        const newRawMessage = {role: 'assistant', content: action.payload.message, version: action.payload.version, code: action.payload.code} as RawMessage;
        if (action.payload.code) {
          const rawMessageIndex = state.rawMessages.push(newRawMessage);
          state.messages.push(convertRawMessage(newRawMessage, rawMessageIndex));
          state.code = action.payload.code;
          state.oldUserMessage = '';
        } else {
          const errorMessage = {role: 'system', content: 'It looks like something may have gone wrong. Please try again. If the problem persists, try rewording your prompt a little.', version: action.payload.version} as RawMessage;
          state.messages.pop();
          state.rawMessages.pop();
          state.messages.push(convertRawMessage(errorMessage));
          state.userMessage = state.oldUserMessage;
        }
      })
      .addCase(sendPrompt.rejected, (state, arg) => {
        console.log('@ sendPrompt.rejected', arg);
        const errorMessage = {role: 'system', content: 'We encountered a problem. Please try again'} as RawMessage;
        state.messages.push(convertRawMessage(errorMessage));
        state.status = 'failed';
      })
  }
});

export const { updateUserMessage, reloadSavedState, clearChat, beginReset, confirmReset, cancelReset } = chatSlice.actions

export const selectMessages = (state: RootState) => state.chat.messages;

export default chatSlice.reducer;

